import { Home, List2, Tiles2, ParAddedtolist, Par } from '@hexa-ui/icons'
import {
  curatorshipsPath,
  dashboardPath,
  listLayoutsPath,
  listPocActivationPath,
  ordersPath,
} from 'routes/Definition'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Styles from './AsideMenu.styles'
import useLayoutStore from 'store/layout-store'
import React from 'react'

function AsideMenu() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { setLayoutId } = useLayoutStore()

  React.useEffect(() => {
    if (pathname) {
      setLayoutId(``)
    }
  }, [pathname])

  return (
    <Styles.Container data-testid="menu-page">
      <Styles.Menu onClick={() => navigate(dashboardPath)} data-status={pathname === dashboardPath}>
        <Home size="large" />
        Dashboard
      </Styles.Menu>
      <Styles.Menu onClick={() => navigate(ordersPath)} data-status={pathname === ordersPath}>
        <List2 size="large" />
        Orders
      </Styles.Menu>
      <Styles.Menu
        onClick={() => navigate(listLayoutsPath)}
        data-status={pathname.includes('/list/layouts')}
      >
        <Tiles2 size="large" />
        Layouts
      </Styles.Menu>
      <Styles.Menu
        onClick={() => navigate(curatorshipsPath)}
        data-status={pathname.includes('/curatorship')}
      >
        <Par size="large" />
        Curatorship
      </Styles.Menu>
      <Styles.Menu
        onClick={() => navigate(listPocActivationPath)}
        data-status={pathname === listPocActivationPath}
      >
        <ParAddedtolist size="large" />
        Pocs Activation
      </Styles.Menu>
    </Styles.Container>
  )
}

export { AsideMenu }
