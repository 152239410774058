/* eslint-disable no-param-reassign */
import { useEnvironment } from '@hooks'
import { useAuthenticationService } from 'admin-portal-shared-services'
import axios, { AxiosResponse } from 'axios'
import { IDelete, IGet, IPath, IPost, IPut } from '../@types/api.types'

export const useApi = () => {
  const { baseUrl: baseURL } = useEnvironment()
  const auth = useAuthenticationService()
  const token = auth.getAuthHeader()

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })

  const api = {
    get: function get<T>(props: IGet): Promise<AxiosResponse<T>> {
      return axiosInstance.get<T>(props.url, props.config)
    },
    put: function put<T, D = unknown>(props: IPut<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.put<T>(props.url, props.data, props.config)
    },
    post: function post<T, D = unknown>(props: IPost<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.post<T>(props.url, props.data, props.config)
    },
    postFormData: function post<T, D = unknown>(props: IPost<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.post<T>(props.url, props.data, {
        ...props.config,
        headers: {
          ...props.config?.headers,
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    patch: function patch<T, D = unknown>(props: IPath<D>): Promise<AxiosResponse<T>> {
      return axiosInstance.patch<T>(props.url, props.data, props.config)
    },
    delete: function deletes<T>(props: IDelete): Promise<AxiosResponse<T>> {
      return axiosInstance.delete<T>(props.url, props.config)
    },
  }

  return api
}
