export const MAX_FILE_SIZE = 2097152
export const ACCEPTED_FILE_TYPES = '.png,.jpg,.jpeg,.JPEG,.JPG,.PNG'
export const ACCEPTED_FILE_TYPE_PNG = 'png'
export const ACCEPTED_FILE_TYPE_JPEG = 'jpeg'
export const ACCEPTED_FILE_TYPE_JPG = 'jpg'
export const CSV_FILE_NAME = 'template-campaign'
export const CSV_FILE_EXTENSION = 'xlsx'
export const CSV_BLOB = new Blob(
  [
    `Please provide the Customer Account ID of the participating POCs. Each ID must be in a row. The first two rows in this file will not be considered.\nPOC ID`,
  ],
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }
)

export const cmsBaseUrls = {
  PROD: 'prod',
  UAT: 'uat',
  QA: 'sit',
  LOCALHOST: 'localhost',
}
