import { createContext } from 'react'
import { PdfFileStateProps } from 'store/pdf-file'

export interface SingleLayoutState extends Omit<PdfFileStateProps, 'isLoading'> {
  idLayout: string
  uploadedFile?: File
  fileBase64: string
  isTraining: boolean
  isPdfViewerLoading: boolean
}

export interface UploadFileContextProps {
  data: SingleLayoutState[]
  dataById: (id?: string) => SingleLayoutState | undefined
  addState: (props: TAddStateProps) => void
  updateStateById: (id: string, newState: TPartialSingleLayoutState) => void
  handleSetPdfFile: (id: string, base64: string) => void
}

export type TPartialSingleLayoutState = Partial<SingleLayoutState>

export type TAddStateProps = Omit<TPartialSingleLayoutState, 'idLayout'> & {
  idLayout: string
}

export const UploadFileContext = createContext({} as UploadFileContextProps)
