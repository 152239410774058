import { useEffect } from 'react'
import { useAppHeader, useAuthenticationService } from 'admin-portal-shared-services'
import { useEnvironment } from 'hooks/useEnvironment/useEnvironment'

interface HeadersProps {
  env?: 'prod' | 'dev'
}

export const useHeaders = (props?: HeadersProps) => {
  const { env = 'prod' } = props || {}
  const auth = useAuthenticationService()
  const token = auth.getAuthHeader()
  const appHeader = useAppHeader()[0]
  const { baseUrl } = useEnvironment()

  useEffect(() => {
    if (appHeader.selectedCountry) {
      localStorage.setItem('selectedCountry', appHeader.selectedCountry)
    }
    if (appHeader.selectedVendor) {
      localStorage.setItem('selectedVendor', appHeader.selectedVendor)
    }
  }, [appHeader.selectedCountry, appHeader.selectedVendor])

  const PO_READER_URL_SUFIX = '/api/link-po-reader-api'
  const PO_READER_CURATORSHIP_SERVICE = '/api/link-po-reader-curatorship-service'

  return {
    token,
    selectedCountry: appHeader.selectedCountry,
    selectedVendor: appHeader.selectedVendor,
    vendorOptions: appHeader.vendorOptions,
    baseUrl: env === 'dev' ? 'http://localhost:3333' : baseUrl + PO_READER_URL_SUFIX,
    curatorshipUrl:
      env === 'dev' ? 'http://localhost:3333' : baseUrl + PO_READER_CURATORSHIP_SERVICE,
  }
}
