import enZA from './languages/en-ZA.json'
import ptBR from './languages/pt-BR.json'

export type Language = 'en-ZA' | 'pt-BR'

export const defaultNS = 'translation'

export const resources = {
  'en-ZA': enZA,
  'pt-BR': ptBR,
} as const
