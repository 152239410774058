import { Grid } from '@hexa-ui/components'
import { defaultAppHeaderConfig, getAppHeaderConfig } from '@utils'
import {
  appHeaderConfig,
  useAppHeader,
  useAuthenticationService,
} from 'admin-portal-shared-services'
import { useEffect } from 'react'
import { useDynamicSidebar } from 'hooks/useDynamicSidebar'
import { PageWrapperProps } from './PageWrapper.types'

export const PageWrapper = ({
  children,
  showBreadcrumb = true,
  pageName = 'DASHBOARD',
  showCountryVendorSelec = false,
  dataTestId = 'default',
  className = 'pageWrapper',
}: PageWrapperProps) => {
  const [, setAppHeaderConfig] = useAppHeader()
  const authentication = useAuthenticationService()

  const persistedSelectedCountry = localStorage.getItem('selectedCountry')
  const persistedSelectedVendor = localStorage.getItem('selectedVendor')

  useEffect(() => {
    const setBreadcrumb = async () => {
      let appHeaderConfig = {
        ...defaultAppHeaderConfig,
        defaultCountry: persistedSelectedCountry || authentication.getCountryB2C(),
        defaultVendor: persistedSelectedVendor || authentication.getVendorId(),
      }

      if (showBreadcrumb) {
        appHeaderConfig = {
          ...appHeaderConfig,
          ...(await getAppHeaderConfig(pageName)),
          countryOptions: authentication.getSupportedCountries(),
        }
      }

      if (showCountryVendorSelec) {
        appHeaderConfig = {
          ...appHeaderConfig,
          vendorSelect: true,
          countrySelect: true,
        }
      }

      setAppHeaderConfig(appHeaderConfig)
    }

    setBreadcrumb()
  }, [pageName, setAppHeaderConfig, showBreadcrumb, showCountryVendorSelec, authentication])

  useDynamicSidebar()

  return (
    <Grid.Container
      className={className}
      sidebar
      type="fluid"
      data-testid={dataTestId}
      style={{
        padding: 0,
        margin: 0,
        width: 'calc(100% - 20px)',
      }}
    >
      {children}
    </Grid.Container>
  )
}
