import {
  SingleLayoutState,
  TAddStateProps,
  TPartialSingleLayoutState,
  UploadFileContext,
} from 'context/uploadFileContext'
import { useMemo, useState } from 'react'
import { convertBase64ToPdf } from 'store/pdf-file'

export const UploadFileProvider = ({ children }: { children: React.ReactNode }) => {
  const [stateArray, setStateArray] = useState<SingleLayoutState[]>([])

  const defaultSingleState: SingleLayoutState = {
    pdfFile: undefined,
    numPages: null,
    isPdfViewerLoading: true,
    pageSelected: 1,
    fileBase64: '',
    idLayout: '',
    isTraining: false,
    uploadedFile: undefined,
  }

  const addState = (props: TAddStateProps) => {
    setStateArray((prevFiles) => [...prevFiles, { ...defaultSingleState, ...props }])
  }

  const updateStateById = (idLayout: string, updatedPdfFile: TPartialSingleLayoutState) => {
    setStateArray((prevFiles) =>
      prevFiles.map((file) => (file.idLayout === idLayout ? { ...file, ...updatedPdfFile } : file))
    )
  }

  const dataById = (idLayout?: string) => {
    return stateArray.find((item) => item.idLayout === idLayout)
  }

  const handleSetPdfFile = (id: string, base64: string) => {
    const pdfFile = convertBase64ToPdf(base64)
    updateStateById(id, { pdfFile })
  }

  const contextValue = useMemo(() => {
    return {
      data: stateArray,
      dataById,
      updateStateById,
      addState,
      handleSetPdfFile,
    }
  }, [stateArray])

  return <UploadFileContext.Provider value={contextValue}>{children}</UploadFileContext.Provider>
}
