import { globalCss, styled } from '@hexa-ui/theme'

export const globalStyles = globalCss({
  body: {
    fontFamily: '$paragraph',
  },
})

export const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  gap: '1.5rem',

  marginTop: '-60px',
  marginLeft: '6.25rem',

  '@media(max-width: 900px)': {
    marginTop: '120px',
    marginLeft: '20px',
  },
})
