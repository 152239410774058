import { ReactNode, createContext, useMemo, useState } from 'react'

export interface ModalContextProps {
  isOpenCreateModalPoc: boolean
  handleOpenCreateModalPoc: () => void
  handleClosedCreateModalPoc: () => void
  isOpenDeleteModalPoc: boolean
  handleOpenDeleteModalPoc: () => void
  handleClosedDeleteModalPoc: () => void
}

export const ModalContextPoc = createContext({} as ModalContextProps | null)

interface ModalPocProviderProps {
  children: ReactNode
}

export function ModalPocProvider({ children }: ModalPocProviderProps) {
  const [isOpenCreateModalPoc, setIsCreateModalPoc] = useState(false)
  const [isOpenDeleteModalPoc, setIsDeleteModalPoc] = useState(false)

  const contextValue = useMemo(() => {
    return {
      isOpenCreateModalPoc,
      isOpenDeleteModalPoc,

      handleOpenCreateModalPoc: () => {
        setIsCreateModalPoc(true)
      },
      handleClosedCreateModalPoc: () => {
        setIsCreateModalPoc(false)
      },

      handleOpenDeleteModalPoc: () => {
        setIsDeleteModalPoc(true)
      },
      handleClosedDeleteModalPoc: () => {
        setIsDeleteModalPoc(false)
      },
    }
  }, [isOpenCreateModalPoc, isOpenDeleteModalPoc])

  return <ModalContextPoc.Provider value={contextValue}>{children}</ModalContextPoc.Provider>
}
