import { globalCss, ThemeProvider as HubThemeProvider } from '@hexa-ui/theme'
import { PropsWithChildren, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css'

const GlobalStyles = globalCss()

export function ThemeProvider({ children }: PropsWithChildren<object>) {
  GlobalStyles()

  useEffect(() => {
    const scriptElement = document.createElement('script')
    scriptElement.setAttribute('src', 'https://cdn.tailwindcss.com')

    scriptElement.async = true // to avoid blocking rendering
    scriptElement.setAttribute(
      'integrity',
      '98dc4abc84e20900c27099cee2f8ec162bf30fef2d7b756fdcf6f7b438973570170792e0154f335377db4419ec1be7ea'
    )
    scriptElement.setAttribute('crossorigin', 'anonymous')

    document.body.appendChild(scriptElement)

    return () => {
      document.body.removeChild(scriptElement)
    }
  }, [])
  return <HubThemeProvider theme="customer">{children}</HubThemeProvider>
}
