import { GetLayoutByIdResponse } from 'api/layouts/get-layout-by-id'
import { create } from 'zustand'

interface LayoutStoreProps {
    modalType: string | null
    layoutId: string
    layoutData: GetLayoutByIdResponse | null
    setLayoutId: (id: string) => void
    setModalType: (type: string) => void
    setLayoutData: (data: GetLayoutByIdResponse | undefined) => void
    resetLayoutData: () => void
}

const useLayoutStore = create<LayoutStoreProps>((set) => ({
    modalType: `create-layout`,
    layoutId: ``,
    layoutData: null,
    setLayoutId: (id: string) => set({ layoutId: id }),
    setModalType: (type: string) => set({ modalType: type }),
    setLayoutData: (data: GetLayoutByIdResponse | undefined) => set({ layoutData: data }),
    resetLayoutData: () => set({ layoutData: null })
}))

export default useLayoutStore

