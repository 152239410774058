import { styled } from '@hexa-ui/theme'

export const Container = styled('div', {
  display: 'flex',
  // flexDirection: 'column',

  gap: '0.25rem',

  width: 'max-content',
  height: 'max-content',

  backgroundColor: '#fff',

  border: '1px solid #e9e9e9',
  borderRadius: 8,

  padding: '0.75rem',

  boxShadow: '0px 0px 12px -4px rgba(0,0,0,0.25)',

  '@media(max-width: 900px)': {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
})

export const Menu = styled('button', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  padding: '0.5rem .75rem',

  borderRadius: 8,
  border: 'none',

  cursor: 'pointer',

  backgroundColor: 'transparent',

  fontSize: '1rem',

  transition: 'all .3s',

  '&:hover': {
    backgroundColor: '#f8f8f8',
  },

  '&[data-status="true"]': {
    backgroundColor: 'black',
    borderRadius: 8,
    color: 'white',
    '& svg': {
      color: 'Yellow',
    },
    '&:hover': {
      backgroundColor: 'black',
    },
  },
})
