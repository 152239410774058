import { EnvConfig, GlobalProvider } from '@providers'
import { useSegmentAnalytics } from '@hooks'

import { Router } from './routes'

import './styles/global.css'

export default function App(props: Readonly<EnvConfig>) {
  addEventListener('warning', (event: any) => {
    event.preventDefault()
  })
  const { SEGMENT_KEY } = props
  useSegmentAnalytics(SEGMENT_KEY)

  return (
    <GlobalProvider {...props}>
      <Router />
    </GlobalProvider>
  )
}
